import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-cs2-marketing/constants/comingSoon.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = ["common:navigation.cs2Marketplace", "CS2 Skins Marketplace"];
const DESCRIPTION = [
  "",
  "Navigate the CS2 Weapons Marketplace for an in-depth look at weapon skins, their rarity, wear level, price range, origin case, and more! Your comprehensive guide to all things CS2 skins all here at Blitz.",
];

function Marketplace() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_CS2}
      pageTitle={TITLE}
      splashImage={`${appURLs.CDN}/blitz/cs2/comingSoon/cs2-soon-profile-weapons.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Marketplace;
